import React, {useState}  from 'react';
import CommonPopup from '../PopupForm/CommonPopup';
import * as Yup from 'yup';

const GrowBusiness = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const options = {
    btn_name: "Submit"
  }

  const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/

  const fields = [
    {
      name: 'name',
      label: '',
      placeholder: 'Full Name*',
      validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'email',
      type: 'email',
      label: '',
      placeholder: 'Add Your Company E-mail Address*',
      validation: Yup.string().email('Invalid email').required('Email is required'),
      customClass: 'md:col-span-6 col-span-12'
    
    },
    {
      name: 'company_name',
      label: '',
      placeholder: 'Company Name',
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'phone',
      label: '',
      placeholder: 'Phone Number',
      validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      customClass: 'md:col-span-6 col-span-12'
    }
  ];
  return (
    <>
      <div className='w-full'  style={{ backgroundImage: `url(${process.env.IMG_CDN+'pharm-landing/vendor/img/elements-img/web/Grow-Your-Business-With-Us.gif'})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
        <div className=' w-11/12 xl:max-w-screen max-w-screen-2xl mx-auto h-auto text-center pt-20 pb-20 text-white'>
          <p className='m-0'>Relevant insights, intense analysis and eloquent reports</p>
          <h2 className='text-3xl'>Grow Your Business With Us</h2>
          <button onClick={openModal} className='btn mt-4'>Register Now</button>
          <CommonPopup isOpen={isModalOpen} closeModal={closeModal} 
            fields={fields}
            options={options}
            page_name="Subscription"
            form_name="grow-your-business-form"
            formTitle="Get 7 Days Free Trial"
            endpoint="/enquiry/pharmdelve-trial"
          />
          </div>
      </div>
    </>
  )
}
export default GrowBusiness;
